// ======================================================================
// IMPORTS
// ======================================================================

import { storePrizes, prizeData, version } from 'js/_options';
import { updateMediaUrls } from 'js/utils';
import lookup from '../../api/game-lookup/elo.json';
import lookup2 from '../../api/game-lookup/ergo.json';

import touchpoint from '../../api/get/store/device-data/touchpoint.json';
import touchpoint2 from '../../api/get/store/device-data/touchpoint2.json';

// ======================================================================
// STATE
// ======================================================================

// const isLocal = process.env.NODE_ENV === 'development';
// const devWin = false;

const initialState = {
  version: version,

  gameId: null,
  store: null,
  identity: null,
  touchpoint: null,

  inited: false,
  content: null,

  winner: null,
  prizeName: null,
  prizeCode: null,

  sectorResult: null,

  allowWin: true,
  prizeData: prizeData,
  spinning: false,
};

// GET PERSISTENT STATE
// Using localstorage

let localStorageState = {};
if (storePrizes) {
  try {
    localStorageState = localStorage.getItem('bat-cannes') ? JSON.parse(localStorage.getItem('bat-cannes')) : {};
    if (localStorageState.version !== version) {
      localStorageState = {};
    }
  } catch (e) {
    // browser does not support local storage, or local storage item does not exist
  }
}

// COMBINE ALL STATES

const state = Object.assign(initialState, localStorageState);

// ======================================================================
// HELPERS
// ======================================================================

// to do - this needs testing and updating to handle 0 remaining prizes
// const getPrizeByIndex = (index, prizeData) => {
//   let currentIndex = 0;
//   for (let i = 0; i < prizeData.length; i++) {
//     currentIndex += prizeData[i].quantity;
//     if (index <= currentIndex) {
//       if (prizeData[i].remaining > 0) {
//         return prizeData[i].name;
//       } else {
//         return null;
//       }
//     }
//   }
// };

const getPrizeDetails = (allowWin, prizeData, winpercent) => {
  let localStorageState = localStorage.getItem('winCount');

  console.log(localStorageState);

  function oneInFiveChance() {
    const randomValue = Math.random();

    if (randomValue < winpercent) {
      return true;
    } else {
      return false;
    }
  }

  if (oneInFiveChance()) {
    function getRandomItem() {
      const randomIndex = Math.floor(Math.random() * prizeData.length);
      return { prize: prizeData[randomIndex], index: randomIndex };
    }

    const { prize, index } = getRandomItem();

    const prizeDetails = {
      winner: true,
      prizeIndex: index,
      prizeName: prize.name,
      prizeCode: 'CODE',
    };

    console.log(prizeDetails);

    return prizeDetails;
  } else {
    console.log('NO PRIZES REMAINING');
    const prizeDetails = {
      winner: false,
      prizeIndex: null,
      prizeName: null,
      prizeCode: null,
    };

    console.log(prizeDetails);
    return prizeDetails;
  }
};

// ======================================================================
// REDUCERS
// ======================================================================

const reducers = {
  setState(rootState, payload) {
    console.log('%c--- setState ---', 'color:#1D9109');
    return { ...rootState, ...payload };
  },
};

// ======================================================================
// EFFECTS
// ======================================================================

const effects = (dispatch) => ({
  init(payload, rootState) {
    console.log('%c--- init ---', 'color:#91074A');
    if (payload.gameId) {
      // save the game ID from URL
      dispatch.appModel.setState({
        gameId: payload.gameId,
        navigate: payload.navigate,
      });
      // next
      dispatch.appModel.getGameData({ gameId: payload.gameId });
    } else {
      dispatch.appModel.setState({
        inited: true,
      });
    }
  },

  getGameData(payload, rootState) {
    console.log('%c--- getGameData ---', 'color:#91074A');

    console.log(payload);
    const lkup = payload.gameId === 'elo' ? lookup : lookup2;

    console.log(lkup);
    // save required info
    dispatch.appModel.setState({
      store: lkup.data.store_uuid,
      touchpoint: lkup.data.touch_point,
      identity: lkup.data.qr_identity,
    });
    // next
    dispatch.appModel.getJSON({ gameId: payload.gameId });
  },

  getJSON(payload, rootState) {
    console.log('%c--- getJSON ---', 'color:#91074A');

    const tch = payload.gameId === 'elo' ? touchpoint : touchpoint2;

    dispatch.appModel.setState({
      inited: true,
      content: updateMediaUrls(tch.data, [], rootState.appModel.touchpoint),
    });
  },

  playGame(payload, rootState) {
    console.log('%c--- playGame ---', 'color:#91074A');

    // determine the prize
    const allowWin = rootState.appModel.allowWin;
    const prizeData = rootState.appModel.prizeData;
    const prizeDetails = getPrizeDetails(allowWin, prizeData, rootState.appModel.content.options.winpercent);

    // update counter
    const updatedprizeData =
      storePrizes && prizeDetails.winner
        ? prizeData.map((item) => {
            if (item.name === prizeDetails.prizeName) {
              item.remaining -= 1;
              item.allocated += 1;
            }
            if (item.remaining < 0) {
              item.remaining = 0;
            }
            return item;
          })
        : prizeData;

    console.log(updatedprizeData);

    // save state
    dispatch.appModel.setState({
      prizeData: updatedprizeData,
      ...prizeDetails,
    });
  },

  resetGame(payload, rootState) {
    console.log('%c--- resetGame ---', 'color:#91074A');
    dispatch.appModel.setState({
      winner: null,
      prizeName: null,
      prizeCode: null,
    });
  },

  resetPrizesRemaining(payload, rootState) {
    console.log('%c--- resetPrizesRemaining ---', 'color:#91074A');

    // reset all prize counts to original values
    const prizeData = rootState.appModel.prizeData;
    const updatedprizeData = prizeData.map((item) => {
      item.remaining = item.quantity;
      return item;
    });

    dispatch.appModel.setState({
      prizeData: updatedprizeData,
    });
  },

  resetPrizesAllocated(payload, rootState) {
    console.log('%c--- resetPrizesAllocated ---', 'color:#91074A');

    // reset all prize counts to original values
    const prizeData = rootState.appModel.prizeData;
    const updatedprizeData = prizeData.map((item) => {
      item.allocated = 0;
      return item;
    });

    dispatch.appModel.setState({
      prizeData: updatedprizeData,
    });
  },

  // playGame(payload, rootState) {
  //   console.log('%c--- playGame ---', 'color:#91074A');
  //   const { api, host } = getEndpoint();
  //   var endpoint = 'spintowin/play';
  //   if (isLocal && host === 'localhost') {
  //     if (devWin) {
  //       endpoint = 'spintowin/play-win';
  //     } else {
  //       endpoint = 'spintowin/play-lose';
  //     }
  //   }
  //   const url = api + endpoint + (host === 'localhost' ? '.json' : '.json');
  //   if (host === 'localhost') {
  //     axios
  //       .get(url, {
  //         headers: {
  //           'X-Requested-With': 'XMLHttpRequest',
  //         },
  //       })
  //       .then((res) => {
  //         console.log(res);
  //         dispatch.appModel.playGameCallback(res.data);
  //       })
  //       .catch((err) => {
  //         // console.log(err);
  //         dispatch.appModel.logError({
  //           message_code: 'spin2win.playGame.1',
  //           message: 'API Error - ' + endpoint,
  //           post_vars: err,
  //         });
  //       });
  //   } else {
  //     axios
  //       .post(
  //         url,
  //         {
  //           store: rootState.appModel.store,
  //           identity: rootState.appModel.identity,
  //         },
  //         {
  //           headers: {
  //             'X-Requested-With': 'XMLHttpRequest',
  //             'Content-Type': 'application/json',
  //           },
  //         }
  //       )
  //       .then((res) => {
  //         console.log(res);
  //         dispatch.appModel.playGameCallback(res.data);
  //       })
  //       .catch((err) => {
  //         // console.log(err);
  //         dispatch.appModel.logError({
  //           message_code: 'spin2win.playGame.2',
  //           message: 'API Error - ' + endpoint,
  //           post_vars: err,
  //         });
  //       });
  //   }
  // },

  // playGameCallback(res, rootState) {
  //   if (res !== null && typeof res.status !== 'undefined' && res.status === 'ok') {
  //     if (res.messages.responseCode === 'winner') {
  //       // next
  //       dispatch.appModel.claimPrize();
  //     } else {
  //       dispatch.appModel.setState({
  //         winner: false,
  //       });
  //     }
  //   } else {
  //     dispatch.appModel.logError({
  //       message_code: 'spin2win.playGame.3',
  //       message: 'API Error - spintowin/play',
  //       post_vars: res,
  //     });
  //   }
  // },

  // claimPrize(payload, rootState) {
  //   console.log('%c--- claimPrize ---', 'color:#3183e0');
  //   const { api, host } = getEndpoint();
  //   const endpoint = 'spintowin/assign';
  //   const url = api + endpoint + (host === 'localhost' ? '.json' : '.json');
  //   if (host === 'localhost') {
  //     axios
  //       .get(url, {
  //         headers: {
  //           'X-Requested-With': 'XMLHttpRequest',
  //         },
  //       })
  //       .then((res) => {
  //         console.log(res);
  //         dispatch.appModel.claimPrizeCallback(res.data);
  //       })
  //       .catch((err) => {
  //         // console.log(err);
  //         dispatch.appModel.logError({
  //           message_code: 'spin2win.claimPrize.1',
  //           message: 'API Error - ' + endpoint,
  //           post_vars: err,
  //         });
  //       });
  //   } else {
  //     axios
  //       .post(
  //         url,
  //         {
  //           store: rootState.appModel.store,
  //           identity: rootState.appModel.identity,
  //         },
  //         {
  //           headers: {
  //             'X-Requested-With': 'XMLHttpRequest',
  //             'Content-Type': 'application/json',
  //           },
  //         }
  //       )
  //       .then((res) => {
  //         console.log(res);
  //         dispatch.appModel.claimPrizeCallback(res.data);
  //       })
  //       .catch((err) => {
  //         // console.log(err);
  //         dispatch.appModel.logError({
  //           message_code: 'spin2win.claimPrize.2',
  //           message: 'API Error - ' + endpoint,
  //           post_vars: err,
  //         });
  //       });
  //   }
  // },

  // claimPrizeCallback(res, rootState) {
  //   if (
  //     res !== null &&
  //     typeof res.status !== 'undefined' &&
  //     res.status === 'ok' &&
  //     res.messages.responseCode === 'assigned'
  //   ) {
  //     dispatch.appModel.setState({
  //       winner: true,
  //       prizeName: res.data.title,
  //       prizeCode: res.data.code,
  //     });
  //   } else {
  //     dispatch.appModel.logError({
  //       message_code: 'spin2win.claimPrize.3',
  //       message: 'API Error - spintowin/assign',
  //       post_vars: res,
  //     });
  //   }
  // },

  logError(payload, rootState) {
    try {
      console.log('%cERROR LOG', 'color:#f00');
      // const { api, host } = getEndpoint();
      // const endpoint = 'log';
      // const url = api + endpoint + (host === 'localhost' ? '.json' : '.json');
      payload.store = rootState.appModel.store;
      payload.version = rootState.appModel.content?.version ? rootState.appModel.content.version : null;
      console.log(payload);
      // if (!isLocal && host !== 'localhost') {
      //   axios.post(url, payload, {
      //     headers: {
      //       'X-Requested-With': 'XMLHttpRequest',
      //       'Content-Type': 'application/json',
      //     },
      //   });
      // }
    } catch (e) {
      console.log(e);
    }
  },
});

// ======================================================================
// EXPORT
// ======================================================================

export const appModel = {
  // initial state
  state,
  // reducers - handle state changes with pure functions
  reducers,
  // selectors - handle state changed based on other state properties
  // selectors,
  // effects - handle state changes with impure functions
  // (use async/await for async actions)
  effects,
};

// ======================================================================
// CODE FROM OLD GAME FOR REFERENCE
// ======================================================================

// generateQr(payload, rootState) {
//   console.log('%c--- generateQr ---', 'color:#3182e0');
//   const timestamp = Date.now().toString().substring(4);
//   const random = rand(111, 999);
//   const gameId = timestamp.toString() + random.toString() + 'qr';
//   const storeId = rootState.internal.getIn(['store', 'uuid']);
//   const { store_miid } = queryString.parse(window.location.search);

//   if (devMode === true || typeof storeId !== 'undefined') {
//     apiRequest(
//       'spintowin/save',
//       {
//         store: storeId,
//         identity: gameId,
//       },
//       (res) => {
//         if (res.status === 'ok') {
//           dispatch.content.setGameId(gameId);
//           dispatch.content.setQrURL(`${qrDomain}?store_miid=${store_miid}&touch_point=game&g=${gameId}`);
//           dispatch.content.setQrState('ready');
//           ReactGA.event({
//             category: 'General',
//             action: 'API Success - spintowin/save',
//           });
//         } else {
//           dispatch.content.logError({
//             message_code: 'error.welcomeTV.generateQr.1',
//             message: 'API Error - /spintowin/save',
//             post_vars: res,
//           });
//           setTimeout(function () {
//             dispatch.content.generateQr();
//             // ReactGA.event({
//             // 	category: 'General',
//             // 	action: 'API Error - spintowin/save'
//             // });
//           }, 2000);
//         }
//       }
//     );
//   } else {
//     dispatch.content.logError({
//       message_code: 'error.welcomeTV.generateQr.2',
//       message: 'Could not generate QR code',
//     });
//   }
// },

// playGame(payload, rootState) {
//   console.log('%c--- playGame ---', 'color:#3183e0');
//   dispatch.content.cancelGameTimeout();
//   dispatch.content.setPlayState('loading');
//   dispatch.content.setShowBlocker(true);
//   const storeId = rootState.internal.getIn(['store', 'uuid']);
//   const gameId = rootState.content.getIn(['gameId']);
//   var endpoint = 'spintowin/play';
//   if (devMode === true) {
//     if (window.location.hostname.indexOf('localhost') >= 0 || window.location.hostname.indexOf('192.168') >= 0) {
//       endpoint = rand(0, 1) === 1 ? 'spintowin/play-win' : 'spintowin/play-lose';
//     }
//   }
//   apiRequest(
//     endpoint,
//     {
//       store: storeId,
//       identity: gameId,
//     },
//     (res) => {
//       console.log(res);
//       if (res !== null && typeof res.status !== 'undefined' && res.status === 'ok') {
//         if (res.messages.resCode === 'winner') {
//           dispatch.content.setWinner(true);
//           dispatch.content.setPrizeName(res.data.title);
//         } else {
//           dispatch.content.setWinner(false);
//         }
//         dispatch.content.cancelGameTimeout();
//         dispatch.content.setPlayState('animating');
//         dispatch.content.setShowBlocker(false);
//         // ReactGA.event({
//         // 	category: 'General',
//         // 	action: 'API Success - spintowin/play'
//         // });
//       } else {
//         if (typeof payload !== 'number') {
//           payload = 1;
//         }
//         if (payload < maxRequestRetries) {
//           payload++;
//           setTimeout(function () {
//             dispatch.content.playGame(payload);
//           }, 1000);
//         } else {
//           clearTimeout(rootState.content.getIn(['cancelGameTimeout']));
//           const history = rootState.content.getIn(['history']);
//           dispatch.content.setShowBlocker(false);
//           history.push('/error' + window.location.search);
//           dispatch.content.logError({
//             message_code: 'error.game.playGame.1',
//             message: 'API Error - spintowin/play',
//             post_vars: res,
//           });
//           // ReactGA.event({
//           // 	category: 'General',
//           // 	action: 'API Error - spintowin/play'
//           // });
//         }
//       }
//     }
//   );
// },

// claim(payload, rootState) {
//   console.log('%c--- claim ---', 'color:#3183e0');
//   dispatch.content.setShowBlocker(true);
//   apiRequest(
//     'spintowin/assign',
//     {
//       store: rootState.internal.getIn(['store', 'uuid']),
//       identity: rootState.content.getIn(['gameId']),
//     },
//     (res) => {
//       console.log(res);
//       const history = rootState.content.getIn(['history']);
//       if (
//         res !== null &&
//         typeof res.status !== 'undefined' &&
//         res.status === 'ok' &&
//         res.messages.resCode === 'assigned'
//       ) {
//         dispatch.content.setPrizeCode(res.data.code);
//         dispatch.content.setShowBlocker(false);
//         history.push('/claimthanks' + window.location.search);
//         // ReactGA.event({
//         // 	category: 'General',
//         // 	action: 'API Success - spintowin/assign'
//         // });
//       } else {
//         if (typeof payload !== 'number') {
//           payload = 1;
//         }
//         if (payload < maxRequestRetries) {
//           payload++;
//           setTimeout(function () {
//             dispatch.content.claim(payload);
//           }, 1000);
//         } else {
//           dispatch.content.setShowBlocker(false);
//           history.push('/error' + window.location.search);
//           dispatch.content.logError({
//             message_code: 'error.game.claim.1',
//             message: 'API Error - /spintowin/assign',
//             post_vars: res,
//           });
//           // ReactGA.event({
//           // 	category: 'General',
//           // 	action: 'API Error - spintowin/assign'
//           // });
//         }
//       }
//     }
//   );
// },

// logError(payload, rootState) {
//   try {
//     console.log('%cERROR LOG', 'color:#f00');
//     payload.store = rootState.internal.getIn(['store', 'uuid']);
//     payload.version = rootState.content.getIn(['content', 'version']);
//     console.log(payload);
//     apiRequest('log', payload, function () {});
//   } catch (e) {
//     //
//   }
// },

//
//
//

// const asyncRequest = async (dispatch, key, { uuid, touch_point, trial_wall_variant }) => {
//   //retrieve the address/location of the server
//   const { api } = getEndpoint();
//   const validVariant = parseVariant(trial_wall_variant);

//   const constructedDeviceName = validVariant ? `samsung_${touch_point}_${validVariant}` : `samsung_${touch_point}`;

//   //combine the base API url with specific server end point
//   // const devEndpoint = `${api}/${override || 'data.json'}`;
//   const url = URL.resolve(api, `get/${uuid}/device-data/${constructedDeviceName}`);

//   //using the API address created, fetch the result
//   const res = await fetch(url, {
//     mode: 'cors',
//     headers: { 'X-Requested-With': 'XMLHttpRequest' },
//   });

//   //if fetch successfully returns the data, convert it to JSON format
//   const { data } = await res.json();

//   const externalAssetPassthroughArray = data !== undefined ? data.ignore_external_asset_passthrough : [];
//   updateMediaUrls(data, externalAssetPassthroughArray, touch_point);

//   //if data is converted to JSON, add the data to the Redux store
//   await dispatch.content.setInitialState(data);

//   return data;
// };

// export function useGetAppData(uuid) {
//   const dispatch = useDispatch();
//   const { touch_point, trial_wall_variant } = useQueryParams();

//   const { data } = useQuery(
//     [
//       'content',
//       {
//         uuid,
//         touch_point,
//         trial_wall_variant,
//       },
//     ],
//     (...args) => asyncRequest(dispatch, ...args)
//   );

//   return data;
// }
