import { useSelector } from 'react-redux';

import { Admin, Disclaimer, Spinner } from 'js/components';
import style from './Wrapper.module.scss';

export const Wrapper = ({ children }) => {
  const inited = useSelector((models) => models.appModel.inited);
  const content = useSelector((models) => models.appModel.content);

  if (!content && !inited) {
    return null;
  }

  if (!content) {
    return (
      <div className={style.outer}>
        <div className={style.inner}>
          <div className={style.pages}>{children}</div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className={style.bg}
        style={{
          backgroundColor: content.options.bg_color,
          backgroundImage: content.options.bg_image,
        }}
      ></div>
      <div
        className={style.outer}
        style={{
          color: content.options.text_color,
        }}
      >
        {content.disclaimer.position === 'top' && <Disclaimer />}
        <div className={style.inner}>
          {/* <Logo /> */}
          <div className={style.pages}>{children}</div>
        </div>
        {content.disclaimer.position === 'bottom' && <Disclaimer />}
      </div>
      <Admin />
    </>
  );
};

export default Wrapper;
