import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button } from 'js/components';
import style from './form.module.scss';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Mailgun from 'mailgun.js';
import { motion } from 'framer-motion';

export const Form = () => {
  const options = useSelector((models) => models.appModel.content.options);
  const content = useSelector((models) => models.appModel.content.pages.form);

  const [error, setError] = useState(false);
  const [erroMessager, setErrorMessage] = useState('Sorry there was a problem. Please try again.');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { gameId } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const mailgun = new Mailgun(FormData);
  const mg = mailgun.client({
    username: 'api',
    key: 'cbd6e9695a3e254ae9ddbbc24193d190-19806d14-59594648',
    url: 'https://api.eu.mailgun.net',
  });

  console.log(options);

  const onSubmit = async (data) => {
    const formData = {
      from: `${data.first_name} ${data.last_name} <${data.email}>`,
      to: options.email_to, // Replace with your email
      subject: 'New Form Submission',
      text: `First Name: ${data.first_name}\nLast Name: ${data.last_name}\nCompany: ${data.company}\nPhone: ${data.phone}`,
    };

    try {
      const email = await mg.messages.create('rts.kerve.com', formData);
      if (email.status === 200) {
        console.log('Email sent:', email);
        setIsSubmitted(true);
      }
      // Optionally, you can handle success here (e.g., show a success message)
    } catch (error) {
      console.error('Error sending email:', error);
      // Handle error (e.g., show an error message)
      setError(true);
      setErrorMessage('Sorry there was a problem sending your message. Please try again.');
    }
  };

  if (isSubmitted) {
    return (
      <motion.div
        data-theme={gameId}
        className={style.wrap}
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate="visible"
        transition={{ duration: 0.5 }}
      >
        <div className={style.brandWrap}>
          <div className={style.brand} style={{ backgroundImage: 'url(' + options.logo + ')' }}></div>
        </div>
        <h2 className={style.title}>{content.thanks_title}</h2>
        <p className={style.intro}>{content.thanks_body}</p>

        <p className={style.disclaimer}>{content.disclaimer}</p>
      </motion.div>
    );
  }
  return (
    <motion.div
      data-theme={gameId}
      className={style.wrap}
      variants={{
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
      }}
      initial="hidden"
      animate="visible"
      transition={{ duration: 0.5 }}
    >
      <div className={style.brandWrap}>
        <div className={style.brand} style={{ backgroundImage: 'url(' + options.logo + ')' }}></div>
      </div>
      <h2 className={style.title}> {content.title}</h2>
      <p className={style.intro}>{content.body}</p>
      {error && <p className={style.error}>{erroMessager}</p>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>First Name*</label>
        <div>
          <input
            style={errors.first_name ? { border: '2px solid #FF0000' } : {}}
            type="text"
            {...register('first_name', {
              required: 'required*',
            })}
          />
          {errors.first_name && <p>{errors.first_name.message}</p>}
        </div>

        <label>Last Name*</label>
        <div>
          <input
            style={errors.last_name ? { border: '2px solid #FF0000' } : {}}
            type="text"
            {...register('last_name', {
              required: 'required*',
            })}
          />
          {errors.last_name && <p>{errors.last_name.message}</p>}
        </div>

        <label>Email*</label>

        <div>
          <input
            type="text"
            style={errors.email ? { border: '2px solid #FF0000' } : {}}
            {...register('email', {
              required: 'required*',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Invalid*',
              },
            })}
          />
          {errors.email && <p>{errors.email.message}</p>}
        </div>

        <label>Phone*</label>

        <div>
          <input
            style={errors.phone ? { border: '2px solid #FF0000' } : {}}
            type="text"
            {...register('phone', {
              required: 'required*',
              pattern: {
                value: /^[0-9]{11}$/, // Validates 10-digit phone numbers
                message: 'Invalid*',
              },
            })}
          />
          {errors.phone && <p>{errors.phone.message}</p>}
        </div>
        <span>* All fields required.</span>

        <Button type="submit">Submit</Button>
      </form>
      <p className={style.disclaimer}>{content.disclaimer}</p>
    </motion.div>
  );
};

export default Form;
