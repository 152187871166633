// ======================================================================
// IMPORTS
// ======================================================================

import { init } from '@rematch/core';
import { storePrizes } from 'js/_options';
import { appModel } from './models.app';

// ======================================================================
// STORE
// ======================================================================

const store = init({
  models: {
    appModel,
  },
  // plugins: [selectPlugin()],
});

// ======================================================================
// LOCALSTORAGE - cache state of store for subsequent visits
// ======================================================================

if (storePrizes) {
  store.subscribe(() => {
    try {
      saveData();
    } catch (e) {
      // browser does not support local storage
    }
  });
}

// debounce - use timeout to prevent writing data too many times in quick succession

let saveDataTimeout;
const saveDataInterval = 100;

const saveData = () => {
  clearTimeout(saveDataTimeout);
  saveDataTimeout = setTimeout(function () {
    doSaveData();
  }, saveDataInterval);
};

// save relevant store data to localstorage

const doSaveData = () => {
  console.log('doing save data');
  localStorage.setItem('bat-cannes', JSON.stringify(store.getState().appModel));

  let localStorageState = localStorage.getItem('winCount');
  if (!localStorageState) {
    localStorage.setItem('winCount', 0);
  }
};

// ======================================================================
// GLOBAL ACCESS
// ======================================================================

// const isLocal = process.env.NODE_ENV === 'development';

// if (isLocal) {
//   setTimeout(function () {
//     if (window) {
//       window.store = store;
//     }
//   }, 500);
//   store.getState().appModel.xxxxxx;
//   store.dispatch.appModel.xxxxxx;
// }

// ======================================================================
// EXPORT
// ======================================================================

export default store;
