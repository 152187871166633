// ======================================================================
// GENERAL OPTIONS
// ======================================================================

// increase this number to clear store data from local storage
export const version = 4;

// how long to show the result sreen for before redirecting back to the beginning
export const resultTimeout = 7000;

// should we use local storage to keep a record of prizes allocated and remaining?
export const storePrizes = true;

// the prizes
export const prizeData = [
  {
    name: '',
    quantity: 100,
    remaining: 100,
    allocated: 0,
  },
];

// export const prizeData = [
//   {
//     name: 'A Vuse Product',
//     quantity: 1,
//     remaining: 1,
//     allocated: 0,
//   },
//   {
//     name: 'A Velo Product',
//     quantity: 1,
//     remaining: 1,
//     allocated: 0,
//   },
//   {
//     name: 'A Glo Product',
//     quantity: 1,
//     remaining: 1,
//     allocated: 0,
//   },
//   {
//     name: 'A Cocktail with Water Bottle',
//     quantity: 1,
//     remaining: 1,
//     allocated: 0,
//   },
//   {
//     name: 'A Water Bottle',
//     quantity: 1,
//     remaining: 1,
//     allocated: 0,
//   },
// ];
