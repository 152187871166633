import { Routes, Route, useNavigate } from 'react-router-dom';

import { Wrapper } from 'js/components';
import { Error, Final, Form } from 'js/pages';
import { useEffect } from 'react';
import store from 'js/store/store';

const App = () => {
  const navigate = useNavigate();

  const gameId = window.location.pathname.split('/')[1];

  useEffect(() => {
    store.dispatch.appModel.init({
      gameId,
      navigate,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <Routes>
        <Route path="/" exact={true} element={<Error />} />
        <Route path="/:gameId" exact={true} element={<Form />} />
        <Route path="/final" exact={true} element={<Final />} />
      </Routes>
    </Wrapper>
  );
};

export default App;
