const getEndpoint = () => {
  // const forceDev = false;
  const {
    // hostname,
    // pathname,
    origin,
  } = window.location;

  // return {
  //   host: 'futuremaker.kerve.com',
  //   api: 'https://portal.futuremaker.kerve.com/api/',
  //   // socket: 'https://node.futuremaker.kerve.com/',
  // };

  // if (forceDev || hostname.match('localhost') || hostname.match('127.0.0.1') || hostname.match('192.168.')) {

  return {
    host: 'localhost',
    api: `${origin}/dev/api/`,
    assetPath: '/dev/',
    // socket: 'http://localhost:4000/',
  };

  // } else if (hostname.match('futuremaker.kerve.com')) {
  //   return {
  //     host: 'futuremaker.kerve.com',
  //     api: 'https://portal.futuremaker.kerve.com/api/',
  //     assetPath: 'https://uat-assets-bat.s3-eu-west-1.amazonaws.com/',
  //     // socket: 'https://node.futuremaker.kerve.com/',
  //   };
  // } else if (hostname.match('futuremaker.com')) {
  //   return {
  //     host: 'futuremaker.com',
  //     api: 'https://portal.futuremaker.com/api/',
  //     assetPath: 'https://d1sz6vagoukyro.cloudfront.net/',
  //     // socket: 'https://node.futuremaker.com/',
  //   };
  // } else {
  //   return {
  //     host: pathname,
  //     api: `${pathname}api/json/`,
  //     assetPath: `${pathname}dev/`,
  //     // socket: 'http://node.vype.kerve.com/',
  //   };
  // }

  // } else if (hostname.match('bat-webserver-dev2.kerve.com')) {
  //   return {
  //     host: 'bat-webserver-dev2.kerve.com',
  //     api: 'https://portal.futuremaker.kerve.com/api/',
  //     assetPath: '',
  //     // socket: 'https://bat-node-dev2.kerve.com/',
  //   };
  // } else if (hostname.match('vype.kerve.com')) {
  //   return {
  //     host: 'vype.kerve.com',
  //     api: 'http://portal.vype.kerve.com/api/',
  //     assetPath: '',
  //     // socket: 'http://node.vype.kerve.com/',
  //   };
  // };
};

export default getEndpoint;
