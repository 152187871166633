import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, MemoryRouter } from 'react-router-dom';

import App from 'js/app/App';
import store from 'js/store/store';
import 'css/styles.scss';

// const isLocal = process.env.NODE_ENV === 'development';
const ActualRouter = BrowserRouter;
const container = document.getElementById('root');
const root = createRoot(container);

console.log('%c********** INIT ' + process.env.REACT_APP_VERSION + ' **********', 'font-weight:bold;color:#c70284;');

// if (!isLocal) {
//   console.log = () => {};
//   console.error = () => {};
//   console.debug = () => {};
// }

root.render(
  <Provider store={store}>
    <ActualRouter>
      <App />
    </ActualRouter>
  </Provider>
);
