import { getEndpoint } from 'js/utils';

// The updateMediaUrls function is designed to mutate the JSON by appending an S3 bucket to the media file directories.
// Buckets are selected based on the windows hostname,

const buildUrlString = (bucket, touch_point, jsonValue, externalAssetPassthroughArray) => {
  const shouldUpdateString =
    externalAssetPassthroughArray !== undefined
      ? !externalAssetPassthroughArray.some((e) => jsonValue.startsWith(e))
      : true;
  if (shouldUpdateString) {
    if (jsonValue.startsWith('/')) {
      return `${bucket}${touch_point}/${jsonValue.substring(1)}`;
    }
    return `${bucket}${touch_point}/jsonValue}`;
  } else {
    return jsonValue;
  }
};

const updateMediaUrls = (data, externalAssetPassthroughArray, touch_point) => {
  if (!data) {
    return;
  }

  touch_point = touch_point.replace('samsung_', '');

  // const { hostname } = window.location;

  const { assetPath } = getEndpoint();

  const mediaTypes = ['.jpg', '.jpeg', '.png', '.mp4', '.svg', '.gif'];
  const urlTypes = ['https://', 'http://'];

  // const getBucketUrl = () => {
  // 	if (hostname.match('futuremaker.kerve.com')) {
  // 		return 'https://uat-assets-bat.s3-eu-west-1.amazonaws.com/';
  // 	} else if (hostname.match('futuremaker.com')) {
  // 		return 'https://d1sz6vagoukyro.cloudfront.net/';
  // 	}
  // 	return 'https://uat-assets-bat.s3-eu-west-1.amazonaws.com/';
  // };

  // if (getBucketUrl() === '' || !data || hostname === 'localhost') {

  // 	return;
  // }

  const dataKeys = Object.keys(data);
  dataKeys.forEach((key) => {
    const dataValue = data[key];

    if (typeof dataValue === 'object') {
      // recurse through & mutate
      updateMediaUrls(dataValue, externalAssetPassthroughArray, touch_point);
      return;
    }

    if (Array.isArray(dataValue)) {
      // recurse through again, since we can perform Object.keys on arrays
      updateMediaUrls(dataValue, externalAssetPassthroughArray, touch_point);
      return;
    }

    if (typeof dataValue === 'string' && mediaTypes.some((t) => dataValue.includes(t))) {
      // we can perform the url switching
      const prevValue = data[key];
      if (!urlTypes.some((u) => prevValue.startsWith(u))) {
        data[key] = buildUrlString(assetPath, touch_point, prevValue, externalAssetPassthroughArray);
      }
    }
  });

  return data;
};

export default updateMediaUrls;
