import style from './final.module.scss';
import logo from '../../../images/kerve.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

export const Final = () => {
  const gameId = useSelector((models) => models.appModel.gameId);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch.appModel.setState({ spinning: false });
  }, []);

  return (
    <div data-theme={gameId} className={style.wrap}>
      <div className={style.brandWrap}>
        <div className={style.brand} style={{ backgroundImage: 'url(' + logo + ')' }}></div>
        <div className={style.prefix}>
          <p>
            If you’re at RTS 2024 and<br></br> interested in creative interactive<br></br> experiences, let’s talk.
          </p>
          <p>
            Come and see<br></br>Gareth Edmunds who will be<br></br> at the Elo or Ergonomic<br></br> Solution stands
            throughout<br></br>
            RTS 2024.
          </p>
        </div>
        <div className={style.contact}>
          <h3>Contact</h3>
          <a href="mailto:gareth@kerve.co.uk">gareth@kerve.co.uk</a>
        </div>
        <div className={style.contact}>
          <h3>Or Visit</h3>
          <a href="https://kerve.co.uk">kerve.co.uk</a>
        </div>
      </div>
    </div>
  );
};

export default Final;
