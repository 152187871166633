import style from './Button.module.scss';

const getComponentType = (props) => {
  // if (props.to) {
  //   return NavLink;
  // } else
  if (props.href) {
    return 'a';
  } else {
    return 'button';
  }
};

const Button = ({ children, ...props }) => {
  if (Object.keys(props).length > 0) {
    const Component = getComponentType(props);
    return (
      <>
        <Component className={style.button} {...props}>
          {children && <div>{children}</div>}
        </Component>
      </>
    );
  }
};

export default Button;
