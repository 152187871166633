import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import style from './Admin.module.scss';

export const Admin = () => {
  const dispatch = useDispatch();

  const allowWin = useSelector((models) => models.appModel.allowWin);
  const prizeData = useSelector((models) => models.appModel.prizeData);

  const clickTimeoutRef = useRef(null);
  const clickCountRef = useRef(0);
  const [isVisible, setIsVisible] = useState(false);

  // listen for a triple click on the button
  const handleClick = () => {
    clearTimeout(clickTimeoutRef.current);
    clickCountRef.current += 1;
    if (clickCountRef.current === 3) {
      handleTripleClick();
      clickCountRef.current = 0;
    } else {
      clickTimeoutRef.current = setTimeout(() => {
        clickCountRef.current = 0;
      }, 300);
    }
  };

  const handleTripleClick = () => {
    setIsVisible(true);
  };

  console.log(prizeData);

  return (
    <>
      <div className={style.hiddenBtn} onClick={handleClick}></div>
      {isVisible && (
        <div className={style.wrap}>
          <div className={style.inner}>
            <table>
              <thead>
                <tr>
                  <th>Prize</th>
                  <th>Quantity</th>
                  <th>Remaining</th>
                  <th>Total Won (Analytics)</th>
                </tr>
              </thead>
              <tbody>
                {prizeData.map((prizeOption, index) => {
                  return (
                    <tr key={index}>
                      <td>{prizeOption.name}</td>
                      <td>{prizeOption.quantity}</td>
                      <td>{prizeOption.remaining}</td>
                      <td>{prizeOption.allocated}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <div className={style.spacer}></div>

            <div>
              <button
                className={style.btn}
                onClick={() => {
                  dispatch.appModel.resetPrizesRemaining();
                }}
              >
                Reset Remaining
              </button>

              <button
                className={style.btn}
                onClick={() => {
                  dispatch.appModel.resetPrizesAllocated();
                }}
              >
                Reset Analytics
              </button>
            </div>

            <div className={style.spacer}></div>

            <div>
              <div
                className={clsx(style.toggle, { [style.toggleVisible]: allowWin })}
                onClick={() => {
                  dispatch.appModel.setState({
                    allowWin: !allowWin,
                  });
                }}
              ></div>
              Allow winning prizes?
            </div>

            <button className={style.close} onClick={() => setIsVisible(false)}>
              X
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Admin;
